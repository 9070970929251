body {
	background-color: #ebecf2;
}

.navbar-inverse {
    background-color: #f8f8f8;
    border-color: #e7e7e7;
}

.bg-blue {
    background-color: #007DC6;
    border-color: #007DC6;
    color: #fff;
}

.login-body {
    padding: 10px;
}

.content-divider > span {
    padding-left: 12px;
    padding-right: 12px;
}

img.captcha {
    border-style: solid;
    border-width: 1px;
}