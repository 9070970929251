.page-title {
    padding: 0 !important;
}

.page-header-content {
    padding: 0 !important;
}

.dashboard-panel .icon-user {
    color: #F7941E;
}

.dashboard-panel .icon-cash {
    color: #00aa00;
}

.dashboard-panel .cash {
    color: #fe5621;
}

.dashboard-panel .icon-help {
    font-size: 64px;
    color: #55007f;
}

.no-padding {
    padding: 0px;
}

.display-block {
    display: block;
}

.dashboard-panel-header {
    border-bottom: 1px solid #f3f3f3 !important;
    font-size: 16px !important;
    text-align: center;
    color: #333;
    min-height: 30px;
    padding: 10px 20px;
    border-bottom: 0;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.82;
}

.dashboard-panel-header > div {
    padding-top: 10px;
}

.dashboard-panel-button {
    padding-top: 10px;
    padding-bottom: 10px;
}

.text-align-right {
    text-align: right;
}

.badge-flat {
    border-width: 2px;
    border-radius: 0;
    line-height: 1.3;
    font-weight: 500;
    text-transform: uppercase;
}

.product-item {
    text-align: center;
    padding: 25px 10px;
}

.product-item > h1 {
    color: #226a98;
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
}

.btn-warning {
    color: #fff;
    background-color: #F7941E;
    border-color: #F7941E;
}

.price {
    color: #f6941f;
    font-size: 20px;
    line-height: 50px;
}

.sidebar-dark .nav-sidebar .nav-item>.nav-link.active {
	background-color: rgba(255,255,255,.1);
    color: #fff;
}

.sidebar-dark .nav-sidebar .nav-link:not(.disabled):hover {
	color: #fff(94, 9, 9);
    background-color: rgba(255,255,255,.1);
}



